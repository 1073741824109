import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useQueryParams from '../../features/hooks/useQueryParams';
import Page from '../../components/Page';
import Input from '../../components/Input';
import Button from '../../components/Button';

interface ChangePasswordForm {
  userId: string,
  password: string,
  passwordRepeat: string,
  token: string,
}

function ChangePassword() {
  const navigate = useNavigate();
  const query = useQueryParams();

  const { mutate } = useMutation<void, void, ChangePasswordForm>((d) => axios.post('/passwordReset/reset', d), {
    onSuccess: () => navigate('/login'),
  });

  const schema = useMemo(() => Yup.object({
    password: Yup.string().min(8, 'mindestens 8 Zeichen').required('Ein Passwort muss angegeben werden'),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwörter müssen übereinstimmen'),
  }), []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      userId: query.get('id') || '',
      token: query.get('token') || '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Page title="neues Passwort vergeben">
      <form className="md:w-1/3" onSubmit={handleSubmit((d) => mutate(d))}>
        <Input
          label="Passwort"
          id="password"
          type="password"
          register={register}
          error={errors.password?.message}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <Input
          label="Passwort wiederholen"
          id="passwordRepeat"
          type="password"
          register={register}
          error={errors.passwordRepeat?.message}
          inputProps={{
            autoComplete: 'new-password',
          }}
        />
        <div className="flex justify-end">
          <Button disabled={isSubmitting} type="submit">Abschicken</Button>
        </div>
      </form>
    </Page>
  );
}

export default ChangePassword;
